<template>
  <div class="container">
    <headers :titles="titles"></headers>
    <div class="column392">
      <div class="column-item date">
        <times></times>
        <div class="btn cursor" @click="gotoHome">首 页</div>
      </div>
      <div class="column-item" style="height:460px">
        <div class="column-item-title title-bg cursor">
          <h4 @click="showTable('data')">电商人才数据库</h4>
        </div>
        <table class="ranking1">
          <thead>
            <tr>
              <th>姓名</th>
              <th>参训课时</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in yjtalentsList" :key="item.id">
              <td>{{item.name}}</td>
              <td>{{item.classHour}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column-item">
        <div class="column-item-title title-bg cursor">
          <h4 @click="showTable('peixun')">电商人才培训跟踪服务情况</h4>
        </div>
        <table class="ranking1">
          <thead>
            <tr>
              <th>姓名</th>
              <th>服务类型</th>
              <th>服务内容</th>
              <th>次数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in yjtalentstrackList" :key="item.id">
              <td>{{item.name}}</td>
              <td>{{item.serviceType}}</td>
              <td>{{item.serviceContent}}</td>
              <td>{{item.total}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column392">
      <div class="column-item btn-box">
        <div class="btn">直播数据</div>
        <div class="btn">溯源数据</div>
      </div>
      <div class="column-item" style="height:240px">
        <div class="column-item-title title-bg">
          <h4>电商人才培训情况</h4>
        </div>
        <div class="monitor">
          <div class="monitor-item">
            <div class="font-num num">{{baseInfo.totalTrainCultivation}}<span>人</span></div>
            <div class="title">电商累计培训</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">{{baseInfo.totalUniversal}}<span>人</span></div>
            <div class="title">电商普及性培训</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">{{baseInfo.totalStart}}<span>人</span></div>
            <div class="title">电商创业培训</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">{{baseInfo.totalDrive}}<span>人</span></div>
            <div class="title">带动就业创业</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">{{baseInfo.hatchNum}}<span>人</span></div>
            <div class="title">电商孵化</div>
          </div>
          <div class="monitor-item">
            <div class="font-num num">{{baseInfo.totalTypical}}<span>人</span></div>
            <div class="title">培育优秀创业典型</div>
          </div>
        </div>
      </div>
      <div class="column-item" style="height:300px">
        <div class="column-item-title title-bg">
          <h4>创业带头人</h4>
        </div>
        <div class="card">
          <el-carousel trigger="click" height="220px" arrow="never" :interval="6000" @change="(index) => leaderIndex = index">
            <el-carousel-item v-for="item in yjstartleaderList" :key="item.id">
              <div class="card-item">
                <div class="card-img cursor" @click="showProduct"><img v-if="item.images.length" :src="item.images[0]" alt=""></div>
                <div class="card-text">
                  <h5>{{item.name}}</h5>
                  <p>{{item.brief}}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="column-item">
        <div class="column-item-title title-bg">
          <h4>创业带头人店铺网络零售额TOP10</h4>
        </div>
        <table class="ranking colour" style="height:310px">
          <thead>
            <tr>
              <th>排名</th>
              <th>店铺名称</th>
              <th>创业带头人</th>
              <th>零售额</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in yjleadershopList" :key="item.id">
              <td class="num font-num">1</td>
              <td>{{item.name}}</td>
              <td>{{item.leader}}</td>
              <td class="font-num">{{item.quota}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column1006">
      <div class="video">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
          :options="playerOptions"></video-player>
      </div>
    </div>
    <div class="column1000">
      <div class="charts2">
        <div class="charts2-item">
          <img src="../assets/images/index/icon15.png" />
          <div>
            <div class="font-num num">{{baseInfo.totalTrain}}<span>人</span></div>
            <div class="charts2-item-title">培训总人数</div>
          </div>
        </div>
        <div class="charts2-item">
          <img src="../assets/images/index/icon16.png" />
          <div>
            <div class="font-num num">{{baseInfo.totalHatch}}<span>人</span></div>
            <div class="charts2-item-title">孵化人数</div>
          </div>
        </div>
        <div class="charts2-item">
          <img src="../assets/images/index/icon17.png" />
          <div>
            <div class="font-num num">{{baseInfo.totalEstart}}<span>人</span></div>
            <div class="charts2-item-title">电商创业人数</div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-box" v-if="showPopup">
      <div class="close cursor" @click="close"></div>
      <div class="popup-title">创业带头人介绍</div>
      <div class="popup-content">
        <div class="avatar"><img v-if="yjstartleaderList[currentIndex].images[0]":src="yjstartleaderList[0].images[0]" width="198" height="211" alt=""></div>
        <div class="product-text">
          <h5>{{yjstartleaderList[currentIndex].name}}</h5>
          <p>简介：{{yjstartleaderList[currentIndex].detailed}}</p>
        </div>
      </div>
      <div class="bottom-img">
        <div class="img-item" v-for="item in yjstartleaderList[currentIndex].images" :key="item">
          <img :src="item" />
        </div>
      </div>
    </div>
    <div class="popup-box1" v-if="showPopup1">
      <div class="close cursor" @click="close1"></div>
      <div class="popup-title1">{{tableTitle}}</div>
      <div class="popup-content1">
        <div class="tableList">
          <a-table :columns="columns" :data-source="tableData" :row-key="record => record.id"
            :rowClassName="rowClassName" :pagination="false"></a-table>
          <!-- <a-pagination
            class="paginationStyle"
            size="small"
            show-size-changer
            show-quick-jumper
            :page-size="pageSize"
            :total="total"
            :current="current"
            @showSizeChange="onShowSizeChange"
            @change="onChange"
          />
 -->


           <el-pagination
            class="paginationStyle"
      @size-change="onShowSizeChange"
      @current-change="onChange"
      :current-page="current"
      
      :page-sizes="[10,,20, 40, 60, 80]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import headers from "@/components/header.vue";
  import times from "@/components/time.vue";
  const columns = []
  const columns1 = [{
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: '身份证号码',
      dataIndex: 'idcard',
      key: 'idcard',
      align: 'center'
    },
    {
      title: '年龄',
      dataIndex: 'age',
      key: 'age',
      align: 'center'
    },
    {
      title: '地址',
      key: 'address',
      dataIndex: 'address',
      align: 'center'
    },
    {
      title: '联系电话',
      key: 'phone',
      dataIndex: 'phone',
      align: 'center'
    },
    {
      title: '参训课时',
      key: 'classHour',
      dataIndex: 'classHour',
      align: 'center'
    },
    {
      title: '参训时间',
      key: 'joinDate',
      dataIndex: 'joinDate',
      align: 'center'
    },
    {
      title: '备注',
      key: 'remarks',
      dataIndex: 'remarks',
      align: 'center'
    },
  ];
  const columns2 = [{
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: '身份证号码',
      dataIndex: 'idcard',
      key: 'idcard',
      align: 'center'
    },
    {
      title: '年龄',
      dataIndex: 'age',
      key: 'age',
      align: 'center'
    },
    {
      title: '联系电话',
      key: 'phone',
      dataIndex: 'phone',
      align: 'center'
    },
    {
      title: '跟踪服务时间',
      key: 'serviceDate',
      dataIndex: 'serviceDate',
      align: 'center'
    },
    {
      title: '服务类型',
      key: 'serviceType',
      dataIndex: 'serviceType',
      align: 'center'
    },
    {
      title: '服务内容',
      key: 'serviceContent',
      dataIndex: 'serviceContent',
      align: 'center'
    },
    {
      title: '累计次数',
      key: 'total',
      dataIndex: 'total',
      align: 'center'
    },
    {
      title: '备注',
      key: 'remarks',
      dataIndex: 'remarks',
      align: 'center'
    },
  ];
  export default {
    name: 'Personnel',
    components: {
      headers,
      times
    },
    data() {
      return {
        tableTitle: '',
        columns,
        columns1,
        columns2,
        showPopup: false,
        showPopup1: false,
        titles: '永嘉县人才培育大数据',
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '4:3', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: require("../assets/video/video.mp4") //url地址
          }],
          poster: "../assets/images/index/poster.jpg", //你的封面地址
          // width: document.documentElement.clientWidth, //播放器宽度
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true //全屏按钮
          }
        },
        yjtalentsList: [],
        yjtalentstrackList: [],
        yjstartleaderList: [],
        currentIndex: 0,
        leaderIndex: 0,
        yjleadershopList: [],
        baseInfo: {
          hatchNum: '', // 电商孵化
          totalDrive: '', // 带动就业创业
          totalEstart: '',// 电商创业总人数
          hatchNum: '', // 电商孵化
          totalStart: '', // 电商孵化
          totalHatch: '', // 孵化总人数
          totalTrain: '', // 培训总人数
          totalTrainCultivation: '', // 电商人才累计培训
          totalTypical: '', // 培育优秀创业典型
          totalUniversal: '', // 电商普及性培训
        },
        tableData: [],
        tableData1: [],
        pageSize: 10,
        current: 1,
        total: 0
      };
    },
    mounted() {
      this.getyjtalents() // 电商人才数据库
      this.getyjtalentstrack() // 电商人才培训跟踪
      this.getObjByMenu() // 基本信息
      this.getyjstartleader() // 领头人
      this.getyjleadershop()  // 领头人店铺
    },
    methods: {
      getyjtalents(isTable) {
        this.$api.get(`/yongjia-biz/yjtalents/page?current=${this.current}&size=${this.pageSize}`)
         .then(({ data }) => {
           if (isTable) this.total = data.total
           if (isTable) this.tableData = data.records || []
           if (!isTable) this.total1 = data.total
           if (!isTable) this.yjtalentsList= data.records || []
           if (!isTable) this.tableData1 = data.records || []
         })
      },
      getyjtalentstrack(isTable) {
        this.$api.get(`/yongjia-biz/yjtalentstrack/page?current=${this.current}&size=${this.pageSize}`)
         .then(({ data }) => {
           if (isTable) this.total = data.total
           if (isTable) this.tableData = data.records || []
           if (!isTable) this.total2 = data.total
           if (!isTable) this.yjtalentstrackList= data.records || []
           if (!isTable) this.tableData2 = data.records || []
         })
      },
      getObjByMenu() {
        this.$api.get('/yongjia-biz/yjshowdata/getObjByMenu/cultivation')
         .then(({ data }) => {
           this.baseInfo= {
             hatchNum: data.hatch_num.value, // 电商孵化
             totalDrive: data.total_drive.value, // 带动就业创业
             totalEstart: data.total_estart.value, // 电商创业总人数
             hatchNum: data.hatch_num.value, // 电商孵化
             totalStart: data.total_start.value, // 电商孵化
             totalHatch: data.total_hatch.value, // 孵化总人数
             totalTrain: data.total_train.value, // 培训总人数
             totalTrainCultivation: data.total_train_cultivation.value, // 电商人才累计培训
             totalTypical: data.total_typical.value, // 培育优秀创业典型
             totalUniversal: data.total_universal.value, // 电商普及性培训
           }
         })
      },
      getyjstartleader() {
        this.$api.get('/yongjia-biz/yjstartleader/page?current=1&size=4')
         .then(({ data }) => {
           this.yjstartleaderList= data.records || []
         })
      },
      getyjleadershop() {
        this.$api.get('/yongjia-biz/yjleadershop/page?current=1&size=10')
         .then(({ data }) => {
           this.yjleadershopList= data.records || []
         })
      },
      rowClassName(record, index) {
        let className = 'light';
        if (index % 2 === 1) className = 'dark';
        return className;
      },
      close() {
        this.showPopup = false
      },
      showProduct() {
        this.showPopup = true
        this.currentIndex = this.leaderIndex
      },

      close1() {
        this.showPopup1 = false
      },
      onShowSizeChange(pageSize) {

        this.pageSize = pageSize
         if (this.tableTitle === '电商人才数据库') {
           this.getyjtalents(true)
         } else {
           this.getyjtalentstrack(true) // 电商人才培训跟踪
         }
      },
      onChange(current) {
        this.current = current
         if (this.tableTitle === '电商人才数据库') {
           this.getyjtalents(true)
         } else {
           this.getyjtalentstrack(true) // 电商人才培训跟踪
         }
      },
      showTable(name) {
        if (name === 'data') {
          this.tableData = this.tableData1
          this.tableTitle = '电商人才数据库'
          this.columns = this.columns1
          this.total = this.total1
        } else {
          this.tableData = this.tableData2
          this.tableTitle = '电商人才培训跟踪服务情况'
          this.columns = this.columns2
          this.total = this.total2
        }
        this.showPopup1 = true

      },
      gotoHome() {
        this.$router.push({
          path: '/'
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/css/pubilc.css';
  @import '../assets/css/font.css';

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    height: 1080px;
    padding: 0 30px;
    box-sizing: border-box;
    background: url('../assets/images/index/bg.png') no-repeat center #000;

    .header {
      width: 1917px;
      height: 167px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -958px;
      z-index: 0;
      background: url('../assets/images/index/header-bg.png') no-repeat center;

      h1 {
        font-size: 40px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 32px;
        text-align: center;
        text-shadow: 0 0 0.2em #fff,
          -0 -0 0.2em #065abe;
      }
    }

    .footer {
      width: 1075px;
      height: 59px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -537px;
      z-index: 0;
      background: url('../assets/images/index/footer-bg.png') no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 30px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em #fff,
          -0 -0 0.2em #065abe;
      }
    }
  }

  .column392 {
    width: 392px;
    margin-top: 75px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 80px;
  }

  .column-item {
    width: 100%;

    .column-item-title {
      width: 100%;
      height: 60px;

      h4 {
        line-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }

    .colour {
      tbody {
        tr {
          &:nth-child(1) {
            .num {
              color: #E5772E;
            }

          }

          &:nth-child(2) {
            .num {
              color: #F19E6C;
            }
          }

          &:nth-child(3) {
            .num {
              color: #F1CD6C;
            }
          }
        }
      }
    }

    .ranking {
      width: 100%;

      tr {
        height: 33px;
        line-height: 33px;

        &:nth-child(even) {
          background: url('../assets/images/index/hover-bg.png') no-repeat center;
        }
      }

      th {
        font-size: 20px;
        font-weight: bold;
        color: #556F95;
      }

      td {
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
      }
    }

    .ranking1 {
      width: 100%;
      height: 360px;
      margin: 20px 0;

      tr {
        height: 33px;
        line-height: 33px;

        &:nth-child(even) {
          background: url('../assets/images/index/hover-bg.png') no-repeat center;
        }
      }

      th {
        font-size: 20px;
        font-weight: bold;
        color: #556F95;
      }

      td {
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
      }
    }
  }

  .date {
    background: url('../assets/images/index/date-icon.png') no-repeat left center;
    height: 28px;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
  }

  .charts1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    div {
      margin-top: 19px;
    }

    .num {
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
    }

    .charts1-item-title {
      font-size: 14px;
      font-weight: bold;
      color: #64CDF9;
      opacity: 0.75;
    }
  }

  .charts2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    .charts2-item {
      width: 22%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      div {
        .num {
          font-size: 28px;
          font-weight: bold;
          color: #00E4FF;

          span {
            font-size: 18px;
            font-weight: bold;
            color: #00E4FF;
          }
        }

        .charts1-item-title {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }


  }

  .btn-box {
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 24px;
  }


  .column850 {
    width: 850px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    margin-left: -425px;
  }

  .column1000 {
    width: 1000px;
    position: absolute;
    left: 50%;
    top: 130px;
    margin-left: -500px;
  }

  .column1006 {
    width: 1006px;
    position: absolute;
    left: 50%;
    top: 258px;
    margin-left: -503px;
  }

  .monitor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;

    .monitor-item {
      width: 33%;
      margin: 10px 0;

      .num {
        font-size: 24px;
        font-weight: bold;

        span {
          font-size: 12px;
          font-weight: normal;
        }
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #739DFF;
      }
    }
  }

  .card {
    padding: 0 10px;
    margin-top: 20px;

    .card-item {
      display: flex;
      flex-direction: row;
      text-align: left;

      .card-img {
        margin-right: 22px;
        width: 120px;
        height: 168px;
        background: #FEFEFE;
        border-radius: 6px;
        img {
          width: 120px;
          height: 168px;
          background: #FEFEFE;
          border-radius: 6px;
        }
      }

      .card-text {
        flex: 1;
        overflow-y:auto;
        height: 168px;
        h5 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 16px;
          color: #fff;
        }

        p {
          font-size: 16px;
          font-weight: bold;
          line-height: 150%;
        }
      }
    }
  }

  // 图标切换效果 修改指示器样式

  ::v-deep .el-carousel__button {
    background: url('../assets/images/index/button-icon.png') no-repeat center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  .video {
    height: 723px;
  }

  .video-player {
    height: 723px;

    .vjs-icon-placeholder {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      z-index: 100;
      background-color: #ffffff;
      border: solid 1px #979797;
    }
  }

  .popup-box {
    position: absolute;
    top: 150px;
    left: 50%;
    margin-left: -403px;
    width: 806px;
    height: 885px;
    border-radius: 20px;
    padding: 30px 50px;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 5px 0 #87fcfe;

    .close {
      width: 17px;
      height: 17px;
      background: url('../assets/images/index/close.png') no-repeat;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 9999;
      transition: 1s;
      -webkit-transition: 1s;

      &:hover {
        cursor: pointer;
        transform: rotate(180deg);
      }
    }

    .popup-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    .popup-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 40px;

      .avatar {
        width: 198px;
        height: 211px;
        margin-right: 30px;
      }

      .product-text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: fit-content;
        max-height: 520px;
        overflow-y:auto;
        h5 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        p {
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          line-height: 180%;
        }
      }
    }

    .bottom-img {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .img-item {
        width: 200px;
        height: 159px;
        border-radius: 10px;
        background: #fff;
        img {
          width: 200px;
          border-radius: 10px;
          height: 159px;
        }
      }
    }
  }


  .popup-box1 {
    position: absolute;
    top: 263px;
    left: 50%;
    max-height:780px;
    overflow-y: scroll;
    margin-left: -619px;
    width: 1238px;
    // height: 550px;
    border-radius: 20px;
    padding: 30px 50px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: rgba(0, 0, 0, 0.8);

    .close {
      width: 17px;
      height: 17px;
      background: url('../assets/images/index/close.png') no-repeat;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 9999;
      transition: 1s;
      -webkit-transition: 1s;

      &:hover {
        cursor: pointer;
        transform: rotate(180deg);
      }
    }

    .popup-title1 {
      font-size: 24px;
      font-weight: bold;
      margin: 0 auto 20px auto;
    }

    .popup-content1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .tableList {
        .paginationStyle {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }
  }
</style>

<style lang="scss">
  // 全局样式
  .ant-table .light {
    background-color: rgba(2, 45, 90, 0.6);
  }

  .ant-table-thead>tr>th {
    background: none;
    font-size: 20px;
    font-weight: bold;
    color: #556F95 !important;
  }

  .ant-table-tbody {

    >tr:hover:not(.ant-table-expanded-row)>td,
    .ant-table-row-hover,
    .ant-table-row-hover>td {
      background: none !important;
    }
  }

  .ant-table-fixed {

    .ant-table-row-hover,
    .ant-table-row-hover>td {
      background: none !important;
    }
  }

  .ant-pagination-item a {
    color: #fff !important;
  }

  .ant-pagination-item,
  .ant-pagination-item-active,
  .ant-select-selection {
    background: none;
  }
</style>
